import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
//import { LoginSessionComponent } from './login-session/login-session.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthComponent
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule)
      }
    ]
  },
  {
    path: 'superadmin',
    children:[
      {
        path: '',
        loadChildren: ()=> import("./superadmin/superadmin.module").then((m) => m.SuperadminModule)
      }
    ]
  },
  {
    path: 'client',
    children: [
      {
        path: '',
        loadChildren: () => import("./client/client.module").then((m) => m.ClientModule)
      }
    ]
  },
  {
    path: 'terrains',
    children: [
      {
        path: '',
        loadChildren: () => import("./logint/logint.module").then((m) => m.logintModule)
      }
    ]
  },
  {
    path: 'affichage',
    children: [
      {
        path: '',
        loadChildren: () => import("./affichage/affichage.module").then((m) => m.AffichageModule)
      }
    ]
  },
  {
    path: 'affichage/:id_screen',
    children: [
      {
        path: '',
        loadChildren: () => import("./affichage/affichage.module").then((m) => m.AffichageModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
