<!-- <p>form works!</p>
<input class="form-control" [(ngModel)]="nom" name="nom" type="text" placeholder="nom">
<input type="password" [(ngModel)]="password" name="password">
<input class="btn btn-danger" type="button" value="Login" (click)="login()">
<div id="result">{{ resultat }}</div> -->

<div class="wrapper fadeInDown d-flex align-items-center py-5 px-4 justify-content-center" style="background-image: url('./assets/images/transport-i-logistyka3.jpg'); bottom: 0; height: 100%; background-repeat: no-repeat; background-size: cover; background-position: center center;">
  <div id="formContent">
    <!-- Tabs Titles -->
    <!-- Icon -->
    <div class="fadeIn first mt-5 mb-4">
      <img src="./assets/images/fss_logo.png" id="icon" alt="User Icon" />
    </div>

    <!-- Login Form -->
    <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
      <input type="text" ngModel id="login" class="fadeIn second" name="lastname_agent" placeholder="Votre prénom">
      <input type="password" ngModel id="password" class="fadeIn third" name="password" placeholder="Mots de passe">
      <button type="submit" class="fadeIn fourth btn" [class.spinner]="loading" [disabled]="loading" >Log In</button>
    </form>

    <!-- Remind Passowrd -->
    <!--div id="formFooter">
      <a class="underlineHover" href="#">Mots de passe oubliée</a>
    </div-->
  </div>
</div>
<!--a class="btn btn-link" (click)="navigateOnTerrs()">click here to logint</a-->
<a class="btn btn-link" (click)="navigateOnAffichage()">AFFICHAGE</a>

