import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingPageService } from "../../services/loading-page/loading-page.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css']
})
export class LoadingPageComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  loadingSubscription: Subscription | undefined;

  constructor(private loadingScreenService: LoadingPageService) {
  }

  ngOnInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value:any) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }

}
