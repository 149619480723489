import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ls from 'localstorage-slim';
//import { AuthService } from './auth/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'FSS admin';

  constructor(){}
   /* window.onbeforeunload=function(){
      localStorage.clear();
      return '';
    }
  }*/
  ngOnInit(): void {
   /* let temps:any=localStorage.getItem('dure');
    console.log('temps',temps);
    let now=new Date().getTime();
    if(now>temps){
      //this.logout();
    }*/
  }

  /*logout(){
    this.authService.logout({token: localStorage.getItem('token')}).subscribe((res:any) => {
      if(res.success === true){
        localStorage.removeItem('token');
        localStorage.removeItem('agent');
        ls.remove('role');
        ls.remove('role_actuelle');
        this.route.navigate(['/login'])
      }
    })
  }*/
}
