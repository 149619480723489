import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from './service/auth.service';
import ls from 'localstorage-slim';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  role:string[] = [];
  loading = false;
  now=new Date();
  duret!:Date;
  constructor(private authService: AuthService, private route: Router) { }

  ngOnInit(): void {

    if(localStorage.getItem('token')){
      if(localStorage.getItem('dure')){
        let temps:any= localStorage.getItem('dure');
        let now = (new Date().getTime());
        if(now<temps){
          this.route.navigate(['/admin']);
        }else{
          localStorage.clear();
        }
      }
    }
    else if(localStorage.getItem('client_token')){
      this.route.navigateByUrl('/client');
    }
    
  }

  navigateOnTerrs () {
    this.route.navigate(['/terrains']);
  }

  navigateOnAffichage () {
    this.route.navigate(['/affichage']);
  }

  login(loginForm: NgForm){
    this.loading = true;
    var datas = loginForm.value;
    if(datas.lastname_agent!="" && datas.password!=""){
      this.authService.login({data : loginForm.value}).subscribe((d:any) => {
        // console.log('SERVICE ', d.agent.on_service);
		
        if(d.agent.on_service === 0){
          Swal.fire('Error', "Connexion non autorisée, votre compte est vérouillé.", 'error');
        }else{
          localStorage.setItem('token', d.token);
//console.log(d.token);
          let now = (new Date().getTime()+15*60*1000).toString();
          //console.log(now);
          localStorage.setItem('dure',now);
          localStorage.setItem('agent', JSON.stringify(d.agent));
		  
          if(d.capacity.length > 1){
            d.capacity.forEach((_capacity:any) => {
              this.role.push(_capacity.type_agent[0].type_agent);
            });
            ls.set('role', this.role, { encrypt: true });
          }else{
            this.role.push(d.capacity[0].type_agent[0].type_agent);
            ls.set('role', this.role, { encrypt: true });
            ls.set('role_actuelle', d.capacity[0].type_agent[0].type_agent, { encrypt: true });
          }
          this.route.navigate(['/admin']);
        }
      }, (err: any) => {
        if(err.status === 401){
          this.loading = false;
          //Swal.fire('Error', "Nom d'Utulisateur ou Mot de passe incorrect", 'error');
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "Nom d'Utulisateur ou Mot de passe incorrect",
            showConfirmButton: false,
            timer: 3000
          });
          loginForm.reset();
        }
      })
    }
    else{
      this.loading =false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Remplir votre champs",
        showConfirmButton: false,
        timer: 3000
      });
    }
  }

}
